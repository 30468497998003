import lightGallery from 'lightgallery';

$(document).on('turbolinks:load', function () {
  const emailInput = document.getElementById("user_email_input");
  const firstNameInput = document.getElementById("first_name_input");
  const lastNameInput = document.getElementById("last_name_input");

  if (emailInput) {
    emailInput.addEventListener("blur", () => {
      const email = emailInput.value;

      if (email) {
        fetch(`/admin/check_user_email?email=${encodeURIComponent(email)}`, {
          headers: { "Accept": "application/json" }
        })
          .then(response => response.json())
          .then(data => {
            if (data.exists) {
              // Если пользователь найден, заполняем имя и фамилию
              firstNameInput.value = data.first_name;
              lastNameInput.value = data.last_name;
              firstNameInput.setAttribute("readonly", true);
              lastNameInput.setAttribute("readonly", true);
            } else {
              // Если пользователь не найден, очищаем поля и даем возможность редактировать
              firstNameInput.value = "";
              lastNameInput.value = "";
              firstNameInput.removeAttribute("readonly");
              lastNameInput.removeAttribute("readonly");
            }
          });
      }
    });
  }

  const courseSelect = document.getElementById("course_select");
  const pricePlanSelect = document.getElementById("price_plan_select");

  if (courseSelect && pricePlanSelect) {
    courseSelect.addEventListener("change", () => {
      const courseId = courseSelect.value;

      // Очистка списка тарифов перед добавлением новых
      // pricePlanSelect.innerHTML = "<option value=''>" + "Select a Price Plan" + "</option>";

      if (courseId) {
        fetch(`/admin/courses/${courseId}/price_plans`, {
          headers: { "Accept": "application/json" }
        })
          .then(response => {
            if (!response.ok) {
              throw new Error(`HTTP error! status: ${response.status}`);
            }
            return response.json();
          })
          .then(data => {
            data.forEach(plan => {
              const option = document.createElement("option");
              option.value = plan.id;
              option.textContent = plan.title;
              pricePlanSelect.appendChild(option);
            });
          })
          .catch(error => {
            console.error("Error fetching price plans:", error);
          });
      }
    });
  }

  $('.admin_lessons_course_name').on('click', function() {
    $(this).toggleClass('open');
    $(this).parent().next('.admin_lessons_course_bottom_container').toggleClass('show');
  });

  $('.course_item_lessons_heading').on('click', function() {
    $(this).toggleClass('open');
    $(this).parent().next('.admin_lessons_container.in_course').toggleClass('show');
  });

  $('.admin_lessons_item_tasks_heading').on('click', function() {
    $(this).toggleClass('open');
    $(this).parent().parent().next('.admin_lessons_item_tasks_container').toggleClass('show');
  });

  $('.html_desctiption a').attr('target', '_blank');

  lightGallery(document.getElementById('course_founder_img'), {
    speed: 500
  });
  
  lightGallery(document.getElementById('course_reviews_imgs'), {
    speed: 500
  });

  $('.education_modal_close').on('click', function() {
    $('#educationModal').removeClass('show');
    $('#educationModal').css("display", "none");
    $('#educationModal').css("padding-right", "");
    $('body').removeClass('modal-open');
    $('body').css("padding-right", "");
    $('.modal-backdrop').remove();
  });

  $('#payment_submit_btn').on('click', function() {
    if( !$('#payment_user_instagram').val() == '' ) {
      let order_id = $('#payment_order_id').val();
      let instagram_acc = $('#payment_user_instagram').val();
      addInstagramToOrder(order_id, instagram_acc)
    }
  });

  $('.food_diary_sections_switch_item:not(.disabled)').on('click', function() {
    let sectionName = $(this).data('section');

    $('.food_diary_sections_switch_item').removeClass('active');
    $(this).addClass('active');

    $('.food_diary_sections_data_container').removeClass('active');
    $("#" + sectionName).addClass('active');
  });

  $('.homepage_section_price_checkout_info_item_heading').on('click', function() {
    let itemContainer = $(this).parent();

    if (itemContainer.hasClass('open')) {
      itemContainer.removeClass('open');
    } else {
      itemContainer.addClass('open');
    }
  });

  $('.homepage_section_pluses_item_heading').on('click', function() {
    let itemContainer = $(this).parent();

    if (itemContainer.hasClass('open')) {
      itemContainer.removeClass('open');
    } else {
      itemContainer.addClass('open');
    }
  });

  $('.user_profile_daily_diary_edit_link').on('click', function() {
    let diaryDataContainer = $('.user_profile_daily_diary_data_container')
    let diaryFormContainer = $('.user_profile_daily_diary_form_container')

    if (diaryDataContainer.hasClass('show')) {
      diaryDataContainer.removeClass('show');
      diaryFormContainer.addClass('show');
    } else {
      diaryDataContainer.addClass('show');
      diaryFormContainer.removeClass('show');
    }
  });

  $('.admin_articles_tags_list_heading.trigger.tags').on('click', function() {
    let tagsContainer = $('.admin_articles_tags_top_container')

    if (tagsContainer.hasClass('show')) {
      $(this).removeClass('open');
      tagsContainer.removeClass('show');
    } else {
      $(this).addClass('open');
      tagsContainer.addClass('show');
    }
  });

  $('.admin_articles_tags_list_heading.trigger.categories').on('click', function() {
    let categoriesContainer = $('.admin_articles_categories_top_container')

    if (categoriesContainer.hasClass('show')) {
      $(this).removeClass('open');
      categoriesContainer.removeClass('show');
    } else {
      $(this).addClass('open');
      categoriesContainer.addClass('show');
    }
  });

  $('.food_diary_calendar_trigger').on('click', function() {
    let calendarContainer = $('.food_diary_calendar_container')

    if (calendarContainer.hasClass('show')) {
      calendarContainer.removeClass('show');
    } else {
      calendarContainer.addClass('show');
    }
  });

  $('#lesson_description_trigger').on('click', function() {
    let descriptionContainer = $('#lesson_description_container')

    if (descriptionContainer.hasClass('show')) {
      $(this).removeClass('open');
      descriptionContainer.removeClass('show');
    } else {
      $(this).addClass('open');
      descriptionContainer.addClass('show');
    }
  });

  $('#lesson_quests_trigger').on('click', function() {
    let questsContainer = $('#lesson_quests_container')

    if (questsContainer.hasClass('show')) {
      $(this).removeClass('open');
      questsContainer.removeClass('show');
    } else {
      $(this).addClass('open');
      questsContainer.addClass('show');
    }
  });

  $('.food_diary_user_data_trigger').on('click', function() {
    let userDataContainer = $(this).parent().parent().find('.food_diary_user_data_form_container')

    if (!userDataContainer.hasClass('no_data')) {
      if (userDataContainer.hasClass('show')) {
        userDataContainer.removeClass('show');
      } else {
        userDataContainer.addClass('show');
      }
    }
  });

  $('.education_lesson_comments_link.reply').on('click', function() {
    $(this).parent().parent().next('.education_lesson_comments_reply_input_container').addClass('d-block');
  });

  $('#food_search_input').keyup(function() {
    if($(this).val().length > 2) {
      $.get($('#food_search').attr('action'), $('#food_search').serialize(), null, 'script');
    }

    return false;
  });

  $('.food_results_item_actions_show_form_trigger').on('click', function(e) {
    $('.food_results_item_actions_input_row_container').removeClass('show');
    $('.food_results_item_actions_show_form_trigger_container').removeClass('hide');
    $('.food_results_item_actions_input_error').removeClass('active');
    $(this).parent().parent().find('.food_results_item_actions_input_row_container').addClass('show');
    $(this).parent().addClass('hide');
  });

  $('.add_food_section_switch').on('click', function() {
    tabName = $(this).data('tabname');
    $('.add_food_section_switch').removeClass('active');
    $('.add_food_recods_tab').removeClass('active');
    $(this).addClass('active');
    $(".add_food_recods_tab[data-tabname='" + tabName +"']").addClass('active');
  });

  $('.add_food_item_btn').on('click', function() {
    $('.food_results_item_actions_input_error').removeClass('active');
    $('.add_food_input').val('');
    userProduct           = $(this).attr('data-user-product');
    foodItemId            = $(this).attr('data-product-id');
    foodItemName          = $(this).attr('data-product-name');
    foodItemCalories      = $(this).attr('data-product-calories');
    foodItemProteins      = $(this).attr('data-product-proteins');
    foodItemFats          = $(this).attr('data-product-fats');
    foodItemCarbohydrates = $(this).attr('data-product-carbohydrates');
    itemGramsInput        = $(this).parent().parent().find('.food_results_item_actions_input');
    itemGrams             =  itemGramsInput.val();

    if (userProduct === 'true') {
      $('#add_user_food_library_item_id').val(foodItemId);
    } else {
      $('#add_food_library_item_id').val(foodItemId);
    }
    $('#add_food_library_item_name').val(foodItemName);

    if ( itemGramsInput.val().length === 0 ) {
      $(this).parent().parent().find('.food_results_item_actions_input_error.empty').addClass('active');
    } else if (!$.isNumeric(itemGrams)) {
      $(this).parent().parent().find('.food_results_item_actions_input_error.numeric').addClass('active');
    } else {
      calculatedFoodItemCalories      = foodItemCalories * (itemGrams / 100);
      calculatedFoodItemProteins      = foodItemProteins * (itemGrams / 100);
      calculatedFoodItemFats          = foodItemFats * (itemGrams / 100);
      calculatedFoodItemCarbohydrates = foodItemCarbohydrates * (itemGrams / 100);

      $('#add_food_library_item_grams').val(itemGrams);

      $('#add_food_library_item_calories').val(calculatedFoodItemCalories.toFixed(2));
      $('#add_food_library_item_proteins').val(calculatedFoodItemProteins.toFixed(2));
      $('#add_food_library_item_fats').val(calculatedFoodItemFats.toFixed(2));
      $('#add_food_library_item_carbohydrates').val(calculatedFoodItemCarbohydrates.toFixed(2));

      console.log($('#add_user_food_library_item_id').val());
      console.log($('#add_food_library_item_id').val());
      console.log($('#add_food_library_item_name').val());
      console.log($('#add_food_library_item_calories').val());
      console.log($('#add_food_library_item_proteins').val());
      console.log($('#add_food_library_item_fats').val());
      console.log($('#add_food_library_item_carbohydrates').val());

      $('#new_user_food_diary_record').submit();
    }
  });

  function checkFoodDiaryLinkParams() {
    let searchParams = new URLSearchParams(window.location.search)

    if (searchParams.has('foodDiaryTab')) {
      let paramData = searchParams.get('foodDiaryTab')

      $('.food_diary_sections_switch_item').removeClass('active');
      $('.food_diary_sections_switch_item[data-section="' + paramData +'"]').addClass('active');

      $('.food_diary_sections_data_container').removeClass('active');
      $("#" + paramData).addClass('active');
    }
  }

  function addInstagramToOrder(order_id, instagram_acc) {
    $.ajax({
      type: "GET",
      url: '/add_instagram_to_order?order_id=' + order_id + '&instagram_acc=' + instagram_acc,
      async: true,
      dataType: "json",
      success: function() {
      },
      error: function() {
      }
    });
  }
});
